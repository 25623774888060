.flashCard {
    display: flex;
    flex-direction: column;
    padding: var(--m-spacing);
    margin-top: var(--m-spacing);
}

.first {
  margin-left: var(--m-spacing);
  width: 100%;
}

.second {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: var(--s-spacing);
}

.third {
    display: flex;
    flex-direction: column;
}

.padding {
    padding: var(--xs-spacing);
}

.container {
    position: fixed;
    top: 0;
    bottom: 0;
    min-height: 100vh;
    height: 100%;
    z-index: 90000;
    min-width: 100vw;
    width: 100%;
    background: white;
}

.main {
}

@media (--desktop) {
    .main {
        margin: 0 30%;
    }
}